<template>
    <div class='abilityDetails'>
        <div class="content">
            <!-- 时间 -->
            <div class="time">
                <svg-icon icon-class="back" />
                <div class="current">{{ current }}</div>
            </div>
            <div class="percentage">
                <div class="num">37.0</div>
                <div>℃</div>
            </div>
            <!-- 折线图 -->
            <div class="echart">
                <div id="BrokenLine" class="histogram"></div>
                <div class="timenode">
                    <div>00:00</div>
                    <div>06:00</div>
                    <div>12:00</div>
                    <div>18:00</div>
                    <div>24:00</div>
                </div>
            </div>
            <!-- 提示 -->
            <div class="tips">
                <div class="less">＜36.0℃</div>
                <div class="greater">36.0℃-37.3℃</div>
                <div class="high">≧37.3℃</div>
            </div>
            <div class="tipsNum">
                <div class="mini">
                    <div class="title">
                        <div class="tipicon"></div>
                        <div>最低值</div>
                    </div>
                    <div class="text">
                        <div class="num">36.0</div>
                        <div>℃</div>
                    </div>
                </div>
                <div class="average">
                    <div class="title">
                        <div class="tipicon"></div>
                        <div>平均值</div>
                    </div>
                    <div class="text">
                        <div class="num">36.5</div>
                        <div>℃</div>
                    </div>
                </div>
                <div class="max">
                    <div class="title">
                        <div class="tipicon"></div>
                        <div>最大值</div>
                    </div>
                    <div class="text">
                        <div class="num">37.0</div>
                        <div>℃</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    VisualMapComponent
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    VisualMapComponent,
    LineChart,
    CanvasRenderer,
    UniversalTransition
]);

export default {
    components: {
    },
    data() {
        return {
            current: `${new Date().getHours()}:${new Date().getMinutes()}`,
        };
    },
    props:{
        
    },
    created() {
        
    },
    mounted() {
        this.$nextTick(() => {
            this.initializationBrokenLine();
        })
    },
    methods: {
        // 初始化折线图
        initializationBrokenLine(){
            if(!document.getElementById('BrokenLine')){
                return
            }
            echarts.use([
                TitleComponent,
                TooltipComponent,
                GridComponent,
                VisualMapComponent,
                LineChart,
                CanvasRenderer,
                UniversalTransition
            ]);
            var chartDom = document.getElementById('BrokenLine');
            var myChart = echarts.init(chartDom);
            var option;
            let series = [];
            for (let index = 0; index < 18; index++) {
                series.push({
                    type: 'bar',
                    itemStyle: { color: '#22BE96'}
                })
                
            }
            const data = ['36.5','36.5','35.8','36.5','36.5','36.5','35.8','35.8','35.8','35.8','35.8','36.5','36.5','36.5','37.5','36.5','36.5','36.5','36.5','36.5','36.5','36.5','36.5','36.5','36.5','36.5','37.0','37.0','37.0','37.0','37.5','37.0','37.0','37.5','37.5','37.5',]
            option = {
                legend: {},
                tooltip: {},
                xAxis: { 
                    type: 'category',
                    show: false,
                    axisLine:{
                        show:false // 是否显示坐标轴
                    },
                    axisTick: {
                        show: false  //是否显示坐标轴刻度
                    },
                    splitLine:{show: false},//去除网格线
                },
                yAxis: {
                    type: 'value',
                    scale : true,
                    // max : 100,
                    min : 35,
                    splitNumber: 5,
                },
                grid: {
                    left: '0',
                    bottom: '3%',
                    containLabel: true
                },
                series: {
                    data: data,
                    type: 'bar',
                    itemStyle: {
                        normal: {
                            color: function (params) {
                                if(params.value < 36){
                                    return '#D330FF';
                                } else if(params.value >= 36 && params.value <= 37.3){
                                    return '#25A8F8';
                                } else{
                                    return '#F35E62';
                                }
                            }
                        }
                    }
                       
                }
            };
            
            option && myChart.setOption(option);
        },
    },
    watch: {
        
    },
    computed: {
        
    },
}
</script>
<style lang='scss' scoped>
.abilityDetails {
    height: 100%;
    padding: 0 20px;
    background: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/modularBag4.png') no-repeat;
    background-size: 100%;
    background-color: #f7f7f7;
    .content {
        width: 100%;
        border-radius: 15px;
        background: #fff;
        // height: 80%;
        padding: 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .time {
            color: #868686;
            display: flex;
            align-items: center;
            font-size: 16px;
            .current {
                font-size: 20px;
                font-weight: 700;
                margin-left: 5px;
            }
        }
        .echart {
            width: 100%;
            position: relative;
            .histogram {
                width: 100%;
                height: 240px;
                /deep/ canvas {
                    width: 110% !important;
                    height: 100% !important;
                    left: -2% !important;
                }
            }
            .timenode {
                width: 90%;
                display: flex;
                justify-content: space-between;
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }
        
        .timeSlot {
            color: #808080;
            font-size: 24px;
            margin: 20px 0;
        }
        .percentage {
            display: flex;
            align-items: center;
            color: #858585;
            font-size: 20px;
            margin-top: 20px;
            .num {
                color: #4F4F4F;
                font-size: 34px;
            }
        }
        .tips {
            width: 100%;
            display: flex;
            justify-content: space-around;
            .less,
            .greater,
            .high {
                color: #808080;
                font-size: 14px;
                position: relative;
                margin: 30px 0;
            }
            .less::before,
            .greater::before,
            .high::before{
                content: "";
                width: 10px;
                height: 10px;
                position: absolute;
                left: -15px;
                top: 50%;
                transform: translateY(-50%);
                border-radius: 5px;
            }
            
            .less::before{
                background-color: #D330FF;
            }
            .greater::before{
                background-color: #25A8F8;
            }
            .high::before{
                background-color: #F35E62;
            }
        }
        .tipsNum {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .mini,
            .average,
            .max {
                width: 32%;
                padding: 5px 10px;
                border-radius: 5px;
                .title {
                    display: flex;
                    align-items: center;
                    color: #808080;
                    .tipicon {
                        width: 5px;
                        height: 10px;
                        border-radius: 5px;
                        margin-right: 5px;
                    }
                }
                .text {
                    color: #808080;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10px;
                    .num {
                        color: #282828;
                        font-size: 24px;
                    }
                }
            }
            .mini {
                border: 1px solid #FCB334;
                background: #FFFBF5;
                .tipicon {
                    background: #FCB334;
                }
            }
            .average {
                border: 1px solid #25A8F8;
                background: #E1F7FF;
                .tipicon {
                    background: #25A8F8;
                }
            }
            .max {
                border: 1px solid #F75C64;
                background: #FFE3E6;
                .tipicon {
                    background: #F75C64;
                }
            }
        }
    }
}
</style>